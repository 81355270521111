import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
function About() {
    // let history = useHistory();
    return (
        <React.Fragment> 
        <section className=" about-bg content-bg">
            <div className="about-header bg-header">
                <img src="/assets/media/about-header.png" className="img-header desktop-header"/>
                <img src="/assets/media/about-tablet-header.png" className="img-header tablet-header" />
                <img src="/assets/media/about-mobile-header.png" className="img-header mobile-header" />
                <div className="about-container main-content">
                    <div className="about-content color-white ">
                        <span className="about-us-header">ABOUT US</span><br/>
                        <span className="about-expert">The Top Experts In Our Field</span>
                        <p className="about-details">Blue Hive Inc. provides efficient and reliable software solutions that aid companies in executing their business goals. We pride ourselves on having a wide range of expertise in the digital sphere, utilizing cost-effective applications, and yielding practical results.</p>
                    </div>
                </div>
            </div>
            <div className="awwawwd-container main-content">
                <div className="content-details">
                    <span className="header">Who We Are<br/> & What We Do</span>
                    <p className="content">Blue Hive Inc. specializes in business application development, creating functional database and software for organizations to implement business operations. A factor that distinguishes Blue Hive from other IT providers is it's capacity to formulate and present cost-efficient applications with an assured high-level productivity.</p>
                </div>
                <div className="awwawwd-bg"></div>
            </div>
            <div className="opc-container main-content">
                <div className="opc-bg"></div>
                <div className="opc-details">
                    <span className="header">Our Process<br/> & Clients</span>
                    <p className="content">With a variety of clients like corporations and SMEs, there is always a new challenge that requires innovative collaboration. We work with you to provide and maintain effective solutions that actually matter to your business.</p>
                    <p className="content">At Blue Hive Inc. we are a team that values passion, adaptability, pragmatism and creativity. These qualities combined with our experience and skillset have produced steadfast internal processes that are dedicated to delivering only the best for our clients.</p>
                </div>
            </div> 
            <div className="wwatbc-container main-content">
                <div className="wwatbc-details">
                    <span className="header">Why We Are <br/>The Best Choice</span>
                    <p className="content">Passion, dedication and trustworthiness are qualities sometimes harder to come by today. At Bluehive Inc., it's not only about delivering reliable high quality products but about meaningful collaboration by building robust partnerships. We believe in teamwork and it's with these qualities, mentality and expertise that we are able to provide results that offer value to our clients and users.</p>
                </div>
            </div>
            <div className="igit-containner main-content">
                <div className="igit-details">
                    <span className="header">Interested?<br/> Get In Touch</span>
                    <p className="content mb-4">Got a question? Need More Details? Want an estimation for your project? Click below to send us a message or request a free estimation.</p>
                    <div className="btn-igit-container">
                        {/* <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                        <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a> */}

                        <a href="/get-an-estimation" className="btn btn-blue1 show-on-mobile">GET ESTIMATION</a>
                        <a href="/contact" className="btn btn-blue1 show-on-mobile">CONTACT US</a>
                        <a href="/get-an-estimation" className="btn btn-blue1 hide-on-mobile">GET AN ESTIMATION</a>
                        <a href="/contact" className="btn btn-blue1 hide-on-mobile">CONTACT US DIRECTLY</a>
                    </div>
                </div>
                <div className="igit-bg"></div>
            </div>
        </section>
        </React.Fragment>
    )
}

export default About;