import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function CustomSystem({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Custom System<br/>Development</span>
                        <p className="services-details-context color-dark-blue">In today's fast paced world, efficiency and streamlined business processes are key to staying ahead of the curve. With a custom system, it is easier to tailor your workflow, procedures and operations to fit your unique business needs. At Blue Hive, we have several years of experience in developing effective custom software systems and can help you and your business stay on top of your field.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Blue Hive Inc. provides the tools to help you streamline your business practices and workflow. Never again do you or your employees have to wrestle with antiquated and inefficient software resulting in losses. With Blue Hive, we provide modern solutions for the modern market - helping your business grow and profit.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We provide custom software development services for all industries. We have worked with many companies of all sizes - so no matter what your business needs or goals are, we have the skills to fulfill them.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>Our development process is clear, concise, and transparent. Throughout the development phases, our team is dedicated to producing high quality work and providing mature project management.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We employ user focused methodologies that allow us to create the most effective solutions. We create systems that are intuitive to use and implement into your business structure.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We take our client's needs, constraints and budget into consideration to try and provide the most cost effective solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Clients We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/ultra-lagree.svg" />
                    <div className="img-group">
                        <img src="/assets/logos/projects/seiko.svg" />
                        <img src="/assets/logos/projects/briefcam.svg" />
                    </div>
                    
                    <img src="/assets/logos/projects/dainese.svg" />
                    
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (CustomSystem);