import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function DatabaseManagement({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Database <br/>Management</span>
                        <p className="services-details-context color-dark-blue">Effective database management systems allow your business to efficiently store, retrieve and organize your data. We offer a variety of database solutions to suit your specific wants and needs. With robust database management, your business operations, organizational processes and administration procedures can run smooth and securely.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">The Blue Hive team of experts are dedicated to ensuring your database management systems are reliable, secure, scalable and efficient. We pride ourselves on swift tech support, tailor made solutions and quality service.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We offer different kinds of database solutions and management. From the typical SQL database like MySQL and MariaDB, to NoSQL like MongoDB and Redis - we got you covered.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We come with experience, qualifications and high standards. We are committed to delivering excellence and offer stable business partnerships that are long lasting.</p>   
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>At Blue Hive, Data protection and security is of the utmost importance to us. We are committed to providing compliant, secure and transparent services.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We study, create and maintain multiple database structures to keep all the applications in tip-top condition. We offer tailor made solutions and are committed to providing you the best value for money.</p>    
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img className="item-50 img-h12m" src="/assets/logos/projects/maria-db.svg"/>
                    <img className="item-50" src="/assets/logos/projects/mongo-db.svg"/>
                    <img className="item-50" src="/assets/logos/projects/redis.svg"/>
                    <img className="item-50 img-h10m" src="/assets/logos/projects/mysql.svg"/>
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (DatabaseManagement);