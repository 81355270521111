import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
import { connect } from 'react-redux';
import {navColor, MobMenu} from '../redux/actions/nav.actions';
import { Carousel } from 'react-responsive-carousel';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function Blogs({nav : {nav_color}, navColor, MobMenu}) {
    // let history = useHistory();
    useState(() =>{
        navColor()
        MobMenu()
    })
    
    
    return (
        <React.Fragment> 
        <section className=" blog-bg content-bg">
            <div className="blog-header bg-header">
                <div className="blog-container">
                    <section className="blog-destkop">
                        <span className="drag-scroll">
                            <i className="fa fa-arrow-right drag-icon"></i>
                            <div className="drag-text">Drag to scroll</div>
                        </span>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={3}
                        >
                            <Slider>
                            <Slide index={0}>
                                <div className="blog-items">
                                    <div className="blog-item-container">
                                        <div className="header">
                                            <span className="title">BLOG</span>
                                            <span className="date">October 22, 2019</span>
                                            <span className="time-duration"><i>7 min read</i></span>
                                        </div>
                                        <div className="content">
                                            <span className="header">Future of Ecommerce: Predictions for 2020 and Beyond</span>
                                            <p>These certainly are unprecedented times. We are in the midst of a global pandemic as well as an economic crisis the likes of which the world has never seen. It’s no surprise that consumers and businesses are unsettled. For a modicum of normalcy and to ensure that they’re staying on top of their financial situation....</p>
                                            <a href="/blog/future-of-ecommerce" className="btn btn-blue-outline">READ ARTICLE</a>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            {/* <Slide index={1}>
                                <div className="blog-items">
                                    <div className="blog-item-container">
                                        <div className="header">
                                            <span className="title">BLOG</span>
                                            <span className="date">April 28, 2020</span>
                                            <span className="time-duration"><i>5 min read</i></span>
                                        </div>
                                        <div className="content">
                                            <span className="header">COVID-19: An Economic Crisis as well as a Pandemic - Technology is Here to Help</span>
                                            <p>These certainly are unprecedented times. We are in the midst of a global pandemic as  well as an economic crisis the likes of which the world has never seen. It's no surprise that consumers and businesses are....</p>
                                            <a href="#" className="btn btn-blue-outline">READ ARTICLE</a>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <Slide index={2}>
                                <div className="blog-items">
                                    <div className="blog-item-container">
                                        <div className="header">
                                            <span className="title">BLOG</span>
                                            <span className="date">October 31, 2017</span>
                                            <span className="time-duration"><i>8 min read</i></span>
                                        </div>
                                        <div className="content">
                                            <span className="header">6 Ways to Make Your Business More Efficient</span>
                                            <p>These certainly are unprecedented times. We are in the midst of a global pandemic as well as an economic crisis the likes of which the world has never seen. It’s no surprise that consumers and businesses are unsettled. For a modicum of normalcy and to ensure that they’re staying on top of their financial situation....</p>
                                            <a href="#" className="btn btn-blue-outline">READ ARTICLE</a>
                                        </div>
                                    </div>
                                </div>    
                            </Slide> */}
                            </Slider>
                        </CarouselProvider>
                    </section>
                    <section className="blog-mobile">
                        <div className="blog-items">
                            <div className="blog-item-container">
                                <div className="header">
                                    <span className="title desktop-title">BLOG</span>
                                    <span className="date">April 2020</span>
                                    <span className="title mobile-title">BLOG</span>
                                    <span className="time-duration"><i>7 min read</i></span>
                                </div>
                                <div className="content">
                                    <span className="header">Future of Ecommerce: Predictions for 2020 and Beyond</span>
                                    <p>These certainly are unprecedented times. We are in the midst of a global pandemic as well as an economic crisis the likes of which the world has never seen. It’s no surprise that consumers and businesses are unsettled. For a modicum of normalcy and to ensure that they’re staying on top of their financial situation....</p>
                                    <a href="/blog/future-of-ecommerce" className="btn btn-blue-outline">READ ARTICLE</a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="blog-items">
                            <div className="blog-item-container">
                                <div className="header">
                                    <span className="title desktop-title">BLOG</span>
                                    <span className="date">April 28, 2020</span>
                                    <span className="title mobile-title">BLOG</span>
                                    <span className="time-duration"><i>5 min read</i></span>
                                </div>
                                <div className="content">
                                    <span className="header">COVID-19: An Economic Crisis as well as a Pandemic - Technology is Here to Help</span>
                                    <p>These certainly are unprecedented times. We are in the midst of a global pandemic as  well as an economic crisis the likes of which the world has never seen. It's no surprise that consumers and businesses are....</p>
                                    <a href="#" className="btn btn-blue-outline">READ ARTICLE</a>
                                </div>
                            </div>
                        </div>
                        <div className="blog-items">
                            <div className="blog-item-container">
                                <div className="header">
                                    <span className="title desktop-title">BLOG</span>
                                    <span className="date">October 31, 2017</span>
                                    <span className="title mobile-title">BLOG</span>
                                    <span className="time-duration"><i>8 min read</i></span>
                                </div>
                                <div className="content">
                                    <span className="header">6 Ways to Make Your Business More Efficient</span>
                                    <p>These certainly are unprecedented times. We are in the midst of a global pandemic as well as an economic crisis the likes of which the world has never seen. It’s no surprise that consumers and businesses are unsettled. For a modicum of normalcy and to ensure that they’re staying on top of their financial situation....</p>
                                    <a href="#" className="btn btn-blue-outline">READ ARTICLE</a>
                                </div>
                            </div>
                        </div>   */}
                    </section>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {navColor, MobMenu})  (Blogs);