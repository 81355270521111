import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
import { connect } from 'react-redux';
import {navColor} from '../redux/actions/nav.actions';
import { Carousel } from 'react-responsive-carousel';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function PageNotFound({nav : {nav_color}, navColor}) {
    // let history = useHistory();
    useState(() =>{
        navColor()
    })
    
    
    return (
        <React.Fragment> 
        <section className="main-container blog-bg content-bg">
            <div className="contact-header bg-header"></div>
            <div className="container-404 ">
                <div className="content row">
                    <div className="col-md-6 text-center context color-white">
                        
                    </div>
                    <div className="col-md-6 text-center context color-theme">
                        <h1 className=" text-center">404</h1>
                        <h1>Page Not Found.</h1>
                    </div>
                </div>
                
            </div>
            
        </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {navColor})  (PageNotFound);