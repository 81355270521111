import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function MobileApp({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Mobile App <br/>Development</span>
                        <p className="services-details-context color-dark-blue">With the exponential growth in Mobile App demand, Blue Hive Inc. has expanded its services to include mobile app development using the most recognized IDEs - Apple Xcode and Android Studio. With the mobile app industry projected to hit $600 billion this year, it is a technology not bound by limits.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Our teams expertise and work ethic produces innovative solutions for the modern market. In a world where mobile devices are so integrated into our way of living, we have the skills to help you launch your mobile projects for the modern market.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We help our clients from concept- ualizing to launching and maintaining the App. We also include suggestions on ways to monetize your App.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We develop using both Apple’s Xcode and Android Studio which are the most recognized integrated development environments (IDE) to develop mobile apps.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We offer complete APP solutions from the development phase as well as setting up the backend cloud resources to run your products consistently.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We take our client's needs, constraints and budget into consideration to try and provide the most cost effective solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/xcode.svg" className="item-50"/>
                    <img src="/assets/logos/projects/android-studio.svg" className="item-50 img-h20m"/>
                    <img src="/assets/logos/projects/flutter.svg" className="item-50"/>
                    <img src="/assets/logos/projects/react.svg" className="item-50"/>
                    
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (MobileApp);