import axios from 'axios';
import { Redirect } from 'react-router-dom';
// const API_ROOT = 'http://54.255.238.234:85';
// const API_ROOT = 'http://inventory-lumen.test';
const API_ROOT = 'https://cwakgblvlc.execute-api.ap-southeast-1.amazonaws.com/';

const http = axios.create({
  baseURL: API_ROOT,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
});

http.interceptors.request.use(
  function (config) {
    // const token = getToken();
    // if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log(error.response);
    if (error.response) {
      if (error.response.status === 401) {
        let appState = {
          isLoggedIn: false,
          user: {},
        };

        localStorage['appState'] = JSON.stringify(appState);
      }
      return Promise.reject(error.response);
    }
  }
);

export default http;
