import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
function home() {
    // let history = useHistory();
    return (
        <React.Fragment> 
        <section className="home-content home-bg content-bg">
            <div className="home-header">
                <img src="/assets/media/home-header.png" className="home-img-bg hide-on-mobile" />
                <img src="/assets/media/Mobile Home - Header Image.png" className="home-img-bg show-on-mobile" />
                <div className="header-container ">
                    <div className="header-content color-white ">
                        <span>Practical Software</span><br/>
                        <span>Solutions To Help</span><br/>
                        <span>You Achieve Your</span><br/>
                        <span>Business Goals</span>
                        <div className="">
                            <a href="/services" className="header-btn btn btn-white-outline text-upper font-nunito btn-home-mb show-on-mobile">view services</a>
                            <a href="/services" className="header-btn btn btn-white-outline text-upper font-nunito btn-home-mb hide-on-mobile">view our services</a>
                            {/* <a href="#" className="header-btn btn  ml-2m btn-white-outline text-upper font-nunito"><span>view products</span></a> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="main-content">
                <div className="who-we-are">
                    <div className="second-level light-bulb bg-z0 f-left"></div>
                    <div className="wwa-content f-left">
                        <span className="header">Who We Are <br/>& What We Do</span>
                        <p className="content">Blue Hive Inc. provides efficient and reliable software solutions that aid companies in executing their business goals. We pride ourselves on having a wide range of expertise in the digital sphere, utilizing cost-effective applications, and yielding practical results.</p>
                        <a href="/about" className="btn btn-blue-outline btn-light-blue-outline" >READ MORE</a>
                    </div>
                    
                </div>
                <div className="we-provide">
                    <span className="header">We Provide Solutions <br/>for Every Industry</span>
                    <ul className="firt-list">
                        <li className="float-left">
                            <ul>
                                <li>
                                    <img src="/assets/icons/home-retail.svg" /><span className="v-center-480" >Retail & Distribution</span>
                                </li>
                                <li>
                                    <img src="/assets/icons/home-hr.svg" /><span className="v-center-480">Hotels & Restaurants</span>
                                </li>
                                <li>
                                    <img src="/assets/icons/healthcare.svg" /><span className="v-center">Healthcare</span>
                                </li>
                            </ul>
                        </li>
                        <li className="float-left">
                            <ul>
                                <li>
                                    <img src="/assets/icons/home-ecom.svg" /><span>E-commerce & Logistics</span>
                                </li>
                                <li>
                                    <img src="/assets/icons/real-estate.svg" /><span className="v-center">Real Estate</span>
                                </li>
                                
                                <li>
                                    <img src="/assets/icons/home-lgu.svg" /><span className="v-center-480 ">Local Government</span>
                                </li>
                                
                            </ul>
                            
                        </li>
                        
                    </ul>
                </div>
                <div className="our-services">
                    
                    <div className="serivces-container">
                        <div className="bg-services"></div>
                        <span className="header">Our Services</span>
                        <div className="services-widget mt-2m">
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-web-dev.svg" />
                                <span className="widget-header">Web<br/>Development</span>
                                <p className="widget-content">We’ll aid you in meeting your branding and marketing necessities</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-mob-app.svg" />
                                <span className="widget-header">Mobile App Development</span>
                                <p className="widget-content">We develop mobile applications to help you gain marketing advantage</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-custom-syst.svg" />
                                <span className="widget-header">Custom System Development</span>
                                <p className="widget-content">Our team utilizes digital solutions that tailors to the client's specific business needs</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-system-int.svg" />
                                <span className="widget-header">System<br/>Integration</span>
                                <p className="widget-content">We fix, improve, and consolidate hardware and software systems to ensure seamless running and consistency</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-cloud-icon.svg" />
                                <span className="widget-header">Cloud Solutions<br/>& Services</span>
                                <p className="widget-content">We offer hosting services that utilize our reliable cloud servers</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-database.svg" />
                                <span className="widget-header">Database Management</span>
                                <p className="widget-content">We offer hosting services that utilize our reliable cloud servers</p>
                            </div>
                            <div className="widget-item text-center">
                                <img src="/assets/icons/home-ui.svg" />
                                <span className="widget-header">UI & UX Development</span>
                                <p className="widget-content">We help manage your front end needs for a positive and interactive user experience</p>
                            </div>
                            <a href="/services" className="widget-item bg-blue text-center">
                                <span className="widget-header-white">Get More<br/>Details</span>
                                <img src="/assets/icons/next.png" className="img-next"/>
                            </a>
                        </div>
                        <div className="bg-hexa-services"></div>
                    </div>
                </div>
                <div className="why-chose-us">
                    <span className="choose-us-header">Why Choose Us?</span>
                    <div className="wcu-widget">
                        <div className="widget-item">
                            <img src="/assets/media/home-wcu-1.png" />
                            <spa className="wcu-widget-header">Experience & Expertise</spa>
                            <p className="wcu-widget-content">With 8 years of professional experience, Blue Hive Inc. is made up of a highly skilled team committed to getting things done smoothly and effectively.</p>
                            <div className="widget-footer">
                                <a href="/about" className="btn btn-blue-outline btn-light-blue-outline btn-block show-on-mobile">ABOUT US</a>
                                <a href="/about" className="btn btn-blue-outline btn-light-blue-outline btn-block hide-on-mobile">READ MORE ABOUT US</a>
                            </div>
                        </div>
                        <div className="widget-item">
                            <img src="/assets/media/home-wcu-pragmatic.png" />
                            <spa className="wcu-widget-header">Pragmatic Solutions</spa>
                            <p className="wcu-widget-content">Blue Hive values functionality and cost efficiency. We focus on practical applications to ensure fully-utilized technologies.</p>
                            <div className="widget-footer">
                                <a href="/services" className="btn btn-blue-outline btn-light-blue-outline btn-block show-on-mobile">OUR SERVICES</a>
                                <a href="/services" className="btn btn-blue-outline btn-light-blue-outline btn-block hide-on-mobile">VIEW OUR SERVICES</a>
                            </div>
                        </div>
                        <div className="widget-item">
                            <img src="/assets/media/home-wcu-icon3.png" />
                            <spa className="wcu-widget-header">Passion for Technology</spa>
                            <p className="wcu-widget-content">We at Blue Hive take pride in seeing our systems and services being utilized well by our clients.</p>
                            <div className="widget-footer">
                                <a href="#" className="btn btn-blue-outline btn-light-blue-outline btn-block show-on-mobile">OUR PRODUCTS</a>
                                <a href="#" className="btn btn-blue-outline btn-light-blue-outline btn-block hide-on-mobile">VIEW OUR PRODUCTS</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-partners">
                    <span className="header">Our Partners</span>
                    <div className="op-widget">
                        <img src="/assets/icons/home-sophos.svg" />
                        <img src="/assets/icons/home-hp.svg" />
                        <img src="/assets/icons/home-sangfor.svg" />
                        <img src="/assets/icons/home-dell.svg" />
                        <img src="/assets/icons/home-honeywell.svg" />
                        <img src="/assets/icons/home-cisco.svg" />
                        <img src="/assets/icons/home-tsc.svg" />
                        <img src="/assets/icons/home-ubiquiti.svg" />
                        <img src="/assets/icons/home-apc.svg" />
                    </div>
                </div>
                <div className="home-estimation">
                    <div className="home-estimation-bg"></div>
                    <span className="estimation-header">Get An Estimation</span>
                    <p className="estimation-content mb-3 mt-2">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                    <a href="/get-an-estimation" className="btn btn-blue1 hide-on-mobile">GET AN ESTIMATION</a>
                    <a href="/get-an-estimation" className="btn btn-blue1 show-on-mobile">GET ESTIMATION</a>
                    <a href="/contact" className="btn btn-blue1 hide-on-mobile">CONTACT US DIRECTLY</a>
                    <a href="/contact" className="btn btn-blue1 show-on-mobile">CONTACT US</a>
                    

                </div>
            </section>
        </section>
        </React.Fragment>
    )
}

export default home;