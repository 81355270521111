import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';

function Footer () {
    let history = useHistory();
    return (
        <React.Fragment> 
            <div className="footer-container">
               <div className="footer-content">
                   <div className="footer-widget footer-logo">
                       <img src="/assets/logos/white.svg" />
                       <p className="color-white mt-2m font-nunito f-14 footer-reserve">©2020 - Bluehive Business Solutions All rights reserved.</p>
                   </div>
                   <div className="footer-widget footer-estimation-container">
                        <p className="font-gilroy color-white f-25 footer-practical">Practical Software Solutions To Help You Achieve Your Business Goals</p>
                        <a href="/get-an-estimation" className="color-lblue footer-estimation">GET AN ESTIMATION</a>
                   </div>
                   <div className="footer-widget width-10 footer-site-map">
                       <ul className="footer-list">
                           <li>
                               <a href="/about">ABOUT</a>
                           </li>
                           <li>
                               <a href="/services">SERVICES</a>
                           </li>
                           {/* <li>
                               <a href="#">PRODUCTS</a>
                           </li> */}
                           <li>
                               <a href="/blog">BLOG</a>
                           </li>
                           <li>
                               <a href="/contact">CONTACT</a>
                           </li>
                       </ul>
                   </div>
                   <div className="line-footer"></div>
                   <div className="footer-widget lower-footer">
                       <ul className="footer-list">
                           <li>
                               OFFICE
                           </li>
                           <li>
                                Web-Jet Building, 88 E. Rodriguez Jr. Ave. Quezon City 
                           </li>
                           <li>&nbsp;</li>
                           <li>EMAIL</li>
                           <li>
                               <a href="mailto:info@bluehive.com.ph">info@bluehive.com.ph</a>
                           </li>
                       </ul>
                   </div>
                   <div className="footer-widget width-11 lower-footer">
                       <ul className="footer-list">
                           <li>
                                TELEPHONE 
                           </li>
                           {/* <li>
                                <a href="tel:029788699">(02) 978.8699</a>
                           </li> */}
                           <li>
                                <a href="tel:026559175 ">(02) 8655.9175 </a>
                           </li>
                           <li>&nbsp;</li>
                           {/* <li>
                                MOBILE 
                           </li>
                           <li>
                                <a href="tel:09178388699">(0917) 838.8699</a>
                           </li> */}
                       </ul>
                   </div>
               </div>
            </div>
        </React.Fragment>
    );
}


export default Footer;