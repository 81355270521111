import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function Ecommerce({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Ecommerce<br/>Services</span>
                        <p className="services-details-context color-dark-blue">With the growing digital migration of stores to the web and the effects of the COVID-19 pandemic, ecommerce platforms and services have never been more necessary than now. At Blue Hive, we are experts in launching your business into the digital sphere using our years of experience, reliable platforms, and strategic design and development processes.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Our key to successful ecommerce ventures is always putting the user experience at the heart of the project strategy. This gives our ecommerce solutions edge when combined with our technical prowess and dedication to quality.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We develop our client projects using renowned ecommerce providers and third party integration services who are constantly pushing the envelope when it comes to efficiency, usability and functionality</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We offer a range of consulting services to fully capture all requirements and requests for your ecommerce service. With this we are able to create effective strategies that fulfill your goals</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We ensure that UI and UX design principles are implemented during the development stage to ensure maximum usability. Responsive and adaptive design are also a large part of this process as we aim to cater to the ever growing smaller device market.</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We aim to create lasting partnerships through meaningful collaboration by providing ongoing support and maintenance for your ecommerce service - helping you to revamp and evolve your ecommerce solutions as times change.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Mastered</span>
                </div>
                <div className="cwww-widget">
                    <img className="item-50" src="/assets/logos/projects/magento.svg" />
                    <img className="item-50" src="/assets/logos/projects/shopify.svg" />
                    <img className="item-50" src="/assets/logos/projects/woocommerce.svg" />
                    <img className="item-50" src="/assets/logos/projects/prestashop.svg" />
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Clients We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img className=" img-h4m" src="/assets/logos/projects/seiko.svg" />
                    <img className=" mt-n2 img-h8m" src="/assets/logos/projects/hayabusa.svg" />
                    <img className=" mt-n2 img-h8m" src="/assets/logos/projects/glamourbox.svg" />
                    <img src="/assets/logos/projects/jbsports.svg" />
                    <img src="/assets/logos/projects/venum.svg" />
                    <img src="/assets/logos/projects/gizmo-central.svg" />
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (Ecommerce);