import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function BusinessEmail({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Business Email<br/>Solutions</span>
                        <p className="services-details-context color-dark-blue">Email is an integral part of almost every business today, allowing us to easily communicate internally and externally. Here at Bluehive, we can equip your business with fully managed, premium cloud email hosting solutions that will ensure your daily business operations are running smoothly and efficiently.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Blue Hive Inc. provides efficient and reliable email hosting and cloud email solutions tailored to your specific business needs. Our offerings are fully managed by us and are therefore up and running 99.99999% at all times.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>For only 149php per month you can get access to the following:</p>
                            <span>
                                Secure POP/IMAP/Webmail<br/>
                                Premium anti-spam and anti-virus <br/>
                                25GB Mailboxes <br/>
                                Outlook auto-setup <br/>
                                Recover deleted email/mailboxes <br/>
                                Custom email filtering <br/>
                                Free user aliases <br/>
                                Chat via Webmail <br/>
                                50MB Attachments
                            </span>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We want to make sure your business email services are accessible at anytime and anywhere. Our email solutions are stable, reliable, and accessible via phone (android, ios and outlook).</p>   
                        </div>
                    </div>
                    
                </div>
            </div>
          
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (BusinessEmail);