import React, { Component, Fragment, useEffect, useState } from 'react';
import {
  Link,
  Router,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { navClick, showMenuMobile } from '../../redux/actions/nav.actions';

function Navbar({
  nav: {
    drop_down,
    mob_menu,
    logo,
    nav_color,
    desktop_hidden,
    estimation,
    mobile_menu,
    mobile_menu_class,
  },
  navClick,
  showMenuMobile,
}) {
  const history = useHistory();
  const location = useLocation();
  let estimateRedirect = '/';

  const goToPreviousPath = () => {
    history.goBack();
  };
  console.log(history.location.pathname);
  console.log(location.pathname);
  return (
    <React.Fragment>
      <div className="content main-content dmt-2m ">
        <nav className="navbar navbar-expand-xl navbar-light bg-transparent z2">
          <a className="navbar-brand" href="/">
            <img className="nav-logo" src={logo} />
          </a>
          <button
            className="navbar-toggler  no-border"
            type="button"
            onClick={() => showMenuMobile(true)}
          >
            <img src={mobile_menu} className={mobile_menu_class} />
          </button>

          <div
            className={mob_menu + ' ' + nav_color}
            id="navbarSupportedContent"
          >
            <a className="navbar-brand mobile-only logo-mobile" href="/">
              <img
                className="nav-logo"
                src="/assets/logos/bluehive_white1.svg"
              />
            </a>
            <a
              href=""
              className="mobile-only cancel-mob-menu"
              type="button"
              onClick={() => showMenuMobile(false)}
            >
              <img src="/assets/icons/cancel.svg" />
            </a>
            <ul className="navbar-nav form-inline my-2 ml-auto">
              <li className={desktop_hidden + ' nav-item  d-mr-2m mobile-only'}>
                <a
                  className={nav_color + ' nav-link text-upper nav-font'}
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className={desktop_hidden + ' nav-item  d-mr-2m'}>
                <a
                  className={nav_color + ' nav-link text-upper nav-font'}
                  href="/about"
                >
                  About
                </a>
              </li>
              <li className={desktop_hidden + ' nav-item d-mr-2m'}>
                <a
                  className={nav_color + ' nav-link text-upper nav-font'}
                  href="/services"
                >
                  Services
                </a>
              </li>

              {/* <li className={desktop_hidden + " nav-item dropdown d-mr-2m"}>
                            <a className="nav-link dropdown-toggle text-upper nav-font" onClick={() => navClick()} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Products
                            </a>
                            <div className={ (drop_down ? 'show ' : ' ')  + 'dropdown-menu arrow-top mobile-sub-memu navi-blue-dropdown border-r1m'} aria-labelledby="navbarDropdown">
                            <a className="dropdown-item sub-nav-font" href="#">Blitz Inventory</a>
                            <a className="dropdown-item sub-nav-font" href="#">Blitz POS <span className="nav-coming-soon">coming soon</span></a>
                            <a className="dropdown-item sub-nav-font" href="#">Blitz Payroll <span className="nav-coming-soon">coming soon</span></a>
                            <a className="dropdown-item sub-nav-font" href="#">Simple Health Pass</a>
                            <a className="dropdown-item sub-nav-font" href="#">Restaurant Table Ordering</a>
                            <a className="dropdown-item sub-nav-font" href="#">PayLokal</a>
                            </div>
                        </li> */}
              <li className={desktop_hidden + ' nav-item d-mr-2m'}>
                <a
                  className={nav_color + ' nav-link text-upper nav-font'}
                  href="/blog"
                >
                  Blog
                </a>
              </li>
              <li className={desktop_hidden + ' nav-item d-mr-2m'}>
                <a
                  className={nav_color + ' nav-link text-upper nav-font'}
                  href="/contact"
                >
                  Contact
                </a>
              </li>
              <li className={desktop_hidden + ' nav-item dropdown d-mr-2m'}>
                <a
                  className={
                    nav_color + ' nav-link text-upper nav-font dropdown-toggle'
                  }
                  onClick={() => navClick()}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Products
                </a>

                <div
                  className={
                    desktop_hidden +
                    ' ' +
                    (mobile_menu ? ' mobile-dropdown ' : '') +
                    (drop_down ? ' show ' : '') +
                    ' dropdown-menu arrow-top mobile-sub-memu navi-blue-dropdown border-r1m'
                  }
                  aria-labelledby="navbarDropdown"
                >
                  <a className="dropdown-item sub-nav-font" href="#">
                    Interactive Displays
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Self Ordering Kiosks
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Queueing Systems
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Inventory System
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Expo Mobile App
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Coffee Shop Wifi
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Bar Alcohol Auditing
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Artificial Intelligence
                  </a>
                  <a className="dropdown-item sub-nav-font" href="#">
                    Smart AI Archiving                  
                  </a>
                </div>
              </li>

              <li className="nav-item  d-mr-2m mobile-only est">
                <a
                  className={
                    nav_color +
                    ' nav-link text-upper nav-font estimation-btn estimation-btn-mobile est-link'
                  }
                  href="/get-an-estimation"
                >
                  Get an estimation
                </a>
              </li>

              {estimation ? (
                <li className="nav-item d-mr-2m">
                  <a
                    onClick={goToPreviousPath}
                    className={
                      nav_color + ' nav-link text-upper nav-font nav-back'
                    }
                  >
                    <i className="fa fa-angle-left "></i>&nbsp;&nbsp;&nbsp;BACK
                  </a>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <a
            className={
              desktop_hidden +
              ' nav-link text-upper estimation-btn estimation-btn-desktop'
            }
            href="/get-an-estimation"
          >
            Get an estimation
          </a>
        </nav>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  nav: state.nav,
});

export default connect(mapStateToProps, { navClick, showMenuMobile })(Navbar);
