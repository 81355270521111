import {
  NAV_DROP_DOWN,
  MOB_MENU,
  LOGO_URL,
  SERVICE_TAB,
  NAV_COLOR,
  DESKTOP_HIDDEN,
  ESTIMATION,
  ESTIMATION_WIDGET,
  MOBILE_NAV,
  EST_FORM,
  EST_BTN,
  CONTACT_FORM,
  CONTACT_BTN,
} from '../types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { NavService } from './_service.nav';

export const navClick = (e) => async (dispatch, getState) => {
  let nav = getState().nav;
  let newBool = nav.drop_down ? false : true;
  dispatch({ type: NAV_DROP_DOWN, payload: newBool });
};
export const showMenuMobile = (show) => async (dispatch) => {
  if (show) {
    dispatch({
      type: MOB_MENU,
      payload:
        'collapse navbar-collapse  df-bold font-nunito color-white mobile-nav show',
    });
  } else {
    dispatch({
      type: MOB_MENU,
      payload:
        'collapse navbar-collapse  df-bold font-nunito color-white mobile-nav',
    });
  }
};

export const imgLogo = (grey) => async (dispatch) => {
  if (grey) {
    let logo = '/assets/logos/bluehive_gray.svg';
    dispatch({ type: LOGO_URL, payload: logo });
  }
};

export const navColor = () => async (dispatch) => {
  dispatch({ type: NAV_COLOR, payload: 'nav-black' });
};

export const serviceTab = (e, target) => async (dispatch) => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  let tab = {
    java: target == 'java' ? 'active' : '',
    php: target == 'php' ? 'active' : '',
    javascript: target == 'javascript' ? 'active' : '',
  };
  dispatch({ type: SERVICE_TAB, payload: tab });
};

export const DeskTopHidden = () => async (dispatch) => {
  dispatch({ type: DESKTOP_HIDDEN, payload: 'desktop-hidden' });
};

export const EstimationAction = () => async (dispatch) => {
  dispatch({ type: DESKTOP_HIDDEN, payload: 'desktop-hidden' });
  dispatch({ type: ESTIMATION, payload: true });
};

export const EstWidget = (key, description) => async (dispatch, getState) => {
  let widget = getState().nav.est_widget;
  widget[key].active = widget[key].active ? false : true;
  let selected = [];
  widget.map((data) => {
    if (data.active) {
      selected.push(data.description);
    }
  });
  localStorage.setItem('est_item', JSON.stringify(selected));
  dispatch({ type: ESTIMATION_WIDGET, payload: widget });
};

export const checkEstItem = () => async (dispatch) => {
  let items = localStorage.getItem('est_item');
  if (JSON.parse(items).length > 0) {
    window.location.href = '/get-an-estimation/details';
  } else {
    alert('No project selected.');
  }
};

export const MobMenu = (white) => async (dispatch, getState) => {
  dispatch({
    type: MOBILE_NAV,
    payload: {
      img: '/assets/icons/mobile-grey-menu.svg',
      cls: white ? 'white-menu' : '',
    },
  });
};

export const estForm = (e, name) => async (dispatch, getState) => {
  let est = getState().nav.est_form;
  est[name] = e.target.value;
  dispatch({ type: EST_FORM, payload: est });
};

export const budgetEst = (range) => async (dispatch, getState) => {
  let est = getState().nav.est_form;
  est.budget.min = range[0];
  est.budget.max = range[1];
  dispatch({ type: EST_FORM, payload: est });
};

export const submitEst = (e) => async (dispatch, getState) => {
  e.preventDefault();
  let est = getState().nav.est_form;
  console.log({ est });
  let items = localStorage.getItem('est_item');
  est.projects = JSON.parse(items);
  if (est.captcha != '') {
    dispatch({ type: EST_BTN, payload: 'SENDING...' });
    NavService.sendEst(est)
      .then((result) => {
        window.location.href = '/get-an-estimation/thank-you';
      })
      .catch((err) => {
        console.log(err);
        alert('Failed to send email.');
        dispatch({ type: EST_BTN, payload: 'SEND' });
      });
  } else {
    alert('Google Captcha Required');
  }
};

export const captchaEst = (token) => async (dispatch, getState) => {
  let est = getState().nav.est_form;
  est.captcha = token;
  dispatch({ type: EST_FORM, payload: est });
};

export const expiredEst = () => async (dispatch, getState) => {
  let est = getState().nav.est_form;
  est.captcha = '';
  dispatch({ type: EST_FORM, payload: est });
};

export const contactForm = (e, name) => async (dispatch, getState) => {
  let est = getState().nav.contact_form;
  est[name] = e.target.value;
  dispatch({ type: CONTACT_FORM, payload: est });
};

export const captchaContact = (token) => async (dispatch, getState) => {
  let est = getState().nav.contact_form;
  est.captcha = token;
  dispatch({ type: CONTACT_FORM, payload: est });
};

export const expiredContact = () => async (dispatch, getState) => {
  let est = getState().nav.contact_form;
  est.captcha = '';
  dispatch({ type: CONTACT_FORM, payload: est });
};

export const submitContact = (e) => async (dispatch, getState) => {
  e.preventDefault();
  let contact = getState().nav.contact_form;
  console.log({ contact });
  // window.location.href= "/get-an-estimation/thank-you";
  if (contact.captcha != '') {
    dispatch({ type: CONTACT_BTN, payload: 'SENDING...' });
    NavService.sendContact(contact)
      .then((result) => {
        if (result) {
          window.location.href = '/get-an-estimation/thank-you';
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Failed to send email.');
        dispatch({ type: CONTACT_BTN, payload: 'SEND' });
      });
  } else {
    alert('Google Captcha Required');
  }
};
