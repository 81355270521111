import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, BrowserHistory } from "react-router-dom";

import PublicRoute from './Utils/PublicRoute';
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import home from './components/home';
import About from './components/About';
import Service from './components/Service';
import SysInt from './components/Services/SysInt';
import MobileApp from './components/Services/Mobile';
import CustomSystem from './components/Services/Custom';
import WebAppDev from './components/Services/WebApp';
import CloudSolution from './components/Services/CloudSolution';
import DatabaseManagement from './components/Services/Database';
import Ict from './components/Services/Ict';
import NetworkSecurity from './components/Services/NetworkSecurity';
import BusinessEmail from './components/Services/BusinessEmail';
import Ecommerce from './components/Services/Ecommerce';
import Consultancy from './components/Services/Consultancy';
import Blogs from './components/Blog';
import Contact from './components/Contact';
import Estimation from './components/Estimation';
import EstDetails from './components/Estimation/EstDetails';
import EstThankYou from './components/Estimation/EstThankYou';


import BlogFuture from './components/Blog/Future';

import PageNotFound from './components/PageNotFound';

// import './Scss/mobile-home.scss';

class App extends Component {
  // constructor(props) {
   
  // }

  render() {
    return (
        <Router>
              <React.Fragment>
                {/* SHOW NAVIGATION WHEN LOGGED IN */}
                <Navbar/>
                <Switch>
                  {/* PLASE FOLLOW PublicRouting AND PrivateRouting */}
                  <Route path="/" exact component={home}/>
                  <Route path="/about" exact component={About}/>
                  <Route path="/services" exact component={Service} />
                  <Route path="/blog" exact component={Blogs} />
                  <Route path="/contact" exact component={Contact} />
                  <Route path="/get-an-estimation" exact component={Estimation} />

                  <Route path='/services/system-integration' exact component={SysInt} />
                  <Route path='/services/mobile-app-development' exact component={MobileApp} />
                  <Route path='/services/custom-system-development' exact component={CustomSystem} />
                  <Route path='/services/web-app-and-website-development' exact component={WebAppDev} />
                  <Route path='/services/cloud-solutions-and-services' exact component={CloudSolution} />
                  <Route path='/services/database-management' exact component={DatabaseManagement} />
                  <Route path='/services/ict-services' exact component={Ict} />
                  <Route path='/services/network-and-security' exact component={NetworkSecurity} />
                  <Route path='/services/business-email-solutions' exact component={BusinessEmail} />
                  <Route path='/services/ecommerce-services' exact component={Ecommerce} />
                  <Route path='/services/consultancy-services' exact component={Consultancy} />

                  <Route path="/get-an-estimation/details" exact component={EstDetails} />
                  <Route path="/get-an-estimation/thank-you" exact component={EstThankYou} />

                  <Route path="/blog/future-of-ecommerce" exact component={BlogFuture} />
                  <Route path="*" component={PageNotFound} />
                </Switch>
                <Footer/>
              </React.Fragment>

      </Router>

    );
  }
}


export default App;
