import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import {navColor, EstimationAction, imgLogo, MobMenu} from '../../redux/actions/nav.actions';

function EstThankYou({nav : {nav_color}, navColor, EstimationAction, imgLogo, MobMenu}) {
    // let history = useHistory();
    useState(() =>{
        navColor()
        EstimationAction()
        imgLogo(true);
        MobMenu();
    })
   
    return (
        <React.Fragment> 
        <section className="main-container estimation-bg content-bg">
            <div className="estimation-content row">
                <div className="etimation-thankyou">
                    <span className="header-thank-you">Thank You!</span>
                    <h1>We Will Get Back To You Soon With Your Estimation</h1>
                    <a href="/" className="btn btn-send btn-reestimate">GO TO HOME</a>
                    <a href="/get-an-estimation" className="btn btn-send btn-reestimate">FILL OUT AGAIN</a>
                </div>
                <div className="bg-thankyou">
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});

export default connect(mapStateToProps, {navColor, EstimationAction, imgLogo, MobMenu}) (EstThankYou);