import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import navReducer from './nav.reducer';
import serviceReducer from './service.reducer';

// persist config whitelist reducer
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: []
}

const rootReducer = combineReducers({
    nav : navReducer,
    servreducer : serviceReducer
})

export default persistReducer(persistConfig, rootReducer);